<template>
  <div class="wrap">
    <van-form input-align="right" error-message-align="right">
      <!-- <img src="@/assets/img/rentPay.png" alt="logo图片" width="100%"> -->
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" :value="内容" /> -->
      <van-field readonly label="姓名" :value="list.customername" />
      <van-field rows="1" autosize type="textarea" readonly label="合同号" :value="list.contract_number" />
      <van-field readonly label="车架号" :value="list.vin" />
      <van-field readonly label="修改前手机号" :value="list.mobile" />
      <van-field readonly label="新手机号" :value="list.newmobile" />
      <van-field readonly label="申请时间" :value="list.apply_time" />
    </van-form>
    <div v-if="list.approval_status=='2'">
      <div offset="1" style="color:#1A1A1A;font-size:20px;margin:15px;">拒绝原因：</div>
      <div class="tips">
        <p>{{list.back_msg}} </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
  data() {
    return {
      list:this.$store.state.dataDetailList
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'info3');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'info3') {
    //     that.$router.push({ path: '/application?flag=1' });
    //   }
    // }, false);
  },
  created(){
  },
  methods: {
  },
};
</script>
<style scoped>
.wrap{
  padding: 10px 0px; 
  background-color: #fff; 
  height: 100%;
  }
.tips{
  color:#808080;
  font-size:13px;
  background-color: #F9F9F9;
  margin: 10px;
  min-height:50px;
  padding: 15px;
  /* margin-left: 12px; */
}
</style>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
</style>